import React, { useState } from 'react';

export const Faq = () => {
  const [activeQuestion, setActiveQuestion] = useState(null);

  const faqs = [
    {
      question: "Por que registrar minha marca?",
      answer: `Registrar sua marca é crucial para proteger seu negócio. Uma marca registrada garante a você o direito exclusivo de uso em todo o território nacional, impede que outros usem sua marca sem autorização, agrega valor ao seu negócio e aumenta a confiança dos seus clientes. Além disso, uma marca registrada pode ser utilizada como garantia em operações financeiras e licenciada para terceiros, gerando novas fontes de renda.`,
    },
    {
      question: "Qual o prazo para o registro da minha marca?",
      answer: `O processo de registro de marca no INPI pode levar em média de 12 a 24 meses, mas fique tranquilo! Nossa equipe trabalha com agilidade e eficiência para que sua marca seja registrada o mais rápido possível, dentro dos prazos legais. Após a sua compra, entraremos em contato em até 7 dias úteis para iniciarmos o processo e te manteremos informado sobre cada etapa.`,
    },
    {
      question: "O que acontece se minha marca for indeferida?",
      answer: `Se, por algum motivo, o INPI indeferir o seu pedido de registro de marca, não se preocupe! Analisaremos o motivo do indeferimento e tomaremos as medidas cabíveis, seja entrando com recurso ou ajustando a sua marca para um novo pedido. Nosso objetivo é garantir que sua marca seja registrada com sucesso.`,
    },
    {
      question: "Quais formas de pagamento vocês aceitam?",
      answer: `Para facilitar o processo de registro da sua marca, aceitamos as principais formas de pagamento: cartão de crédito (parcelamento em até 12x), boleto bancário, transferência bancária e Pix. Escolha a opção que melhor se adapta à sua necessidade e finalize sua compra com segurança e praticidade.`,
    },
    {
      question: "Preciso de um advogado para registrar minha marca?",
      answer: `Embora você possa dar entrada no processo de registro de marca sozinho, contar com a assessoria de um advogado especialista em propriedade intelectual aumenta consideravelmente as chances de sucesso do seu registro. Nossa equipe de advogados experientes cuidará de todo o processo para você, desde a pesquisa de anterioridade até a emissão do certificado de registro, garantindo que sua marca esteja protegida da forma correta.`,
    },
  ];

  const handleToggle = (index) => {
    setActiveQuestion(activeQuestion === index ? null : index);
  };

  return (
    <div id="faq" className="faq-container">
       <h2><i className="fa fa-question-circle" style={{ marginRight: '10px' }}></i>Perguntas Frequentes</h2>
      <div className="faq-list">
        {faqs.map((faq, index) => (
          <div key={index} className="faq-item">
            <div
              className="faq-question"
              onClick={() => handleToggle(index)}
              style={{ cursor: 'pointer', fontWeight: 'bold' }}
            >
              {faq.question}
            </div>
            {activeQuestion === index && (
              <div className="faq-answer" style={{ marginTop: '10px' }}>
                <p>{faq.answer}</p>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};


