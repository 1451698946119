import React from "react";

export const About = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-7">
            {" "}
            <img src="img/IMG_4146.jpg" className="img-responsive" alt="" />{" "}
          </div>
          <div className="col-xs-12 col-md-5">
            <div className="about-text">
              <h2>Por que Registrar sua Marca Conosco?</h2>
              {/* <p>{props.data ? props.data.paragraph : "loading..."}</p> */}

              <div className="list-style">
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {/* {props.data
                      ? props.data.Why.map((d, i) => (
                          <li key={`${d}-${i}`}>{d}</li>
                        ))
                      : "loading"} */}
                    <li>Agilidade e praticidade: "Plataforma 100% online, sem burocracia."</li>
                   <li>
                   Autonomia: "Você no controle do processo, do início ao fim."</li>
                   <li>Segurança: "Especialistas em registro de marcas cuidando de tudo para você."</li>
                   <li>Tecnologia: "Sistema inteligente que garante a segurança dos seus dados."</li>
                   <li>Suporte especializado: "Equipe disponível para tirar suas dúvidas."</li>
                   <li>Preço justo: "Planos acessíveis para todos os tipos de marcas."</li>
                   <li>Prova social: Incluir depoimentos de clientes satisfeitos ou número de marcas já registradas.</li>
                  </ul>
                </div>
                {/* <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why2.map((d, i) => (
                          <li key={`${d}-${i}`}> {d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
