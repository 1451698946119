import React, { useState } from "react";
import Modal from "react-modal";
import emailjs from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const customStyles = {
  content: {
    top: '57%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '90vw', // Responsivo em largura
    maxWidth: '700px', // Limite máximo para largura
    height: 'auto', // Ajusta a altura com base no conteúdo
    maxHeight: '80vh', // Evita que ultrapasse a altura da viewport
    padding: '15px',
    borderRadius: '8px',
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.3)',
    overflowY: 'auto', // Scroll automático se o conteúdo exceder a altura
    color: 'black',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
  },
};

// Defina seu elemento de aplicativo para acessibilidade
Modal.setAppElement('#root');

export const Features = (props) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formVisible, setFormVisible] = useState(false);
  const [userName, setUserName] = useState("");
  const [userWhatsapp, setUserWhatsapp] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [brandName, setBrandName] = useState(""); // Campo para o nome da marca
  const [selectedCategory, setSelectedCategory] = useState(""); // Nova categoria selecionada

  const notifySuccess = () => toast.success("Obrigado! Fale conosco no whatsapp, ou aguarde o nosso contato!");

  // Endereço de e-mail do destinatário
  const recipientEmail = "gamaefreire@gmail.com"; // E-mail do destinatário

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
    setLoading(false);
    setFormVisible(false);
    setBrandName(""); // Reseta o nome da marca ao fechar
    setUserName("");
    setUserWhatsapp("");
    setUserEmail("");
    setSelectedCategory(""); // Reseta a categoria
    setAcceptedTerms(false);
  }

  function handleConclude() {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setFormVisible(true);
    }, 2000);
  }

  function handleFinalize() {
    const templateParams = {
      from_name: userName,
      from_whatsapp: userWhatsapp,
      from_email: userEmail,
      brand_name: brandName,
      brand_category: selectedCategory,
      to_email: recipientEmail, // Adiciona o e-mail do destinatário
    };

    // Envie o e-mail usando EmailJS
    // Envie o e-mail usando EmailJS
    emailjs.send("service_gzj313h", "template_mqgqfd8", templateParams, "VRAaTZA59RyX7in_2")
      .then((response) => {
        console.log('Email enviado com sucesso!', response.status, response.text);
        notifySuccess();

        // Aguarde 3 segundos antes de redirecionar
        setTimeout(() => {
          window.location.href = "https://api.whatsapp.com/send?phone=5582991023387&text=Ol%C3%A1%2C%20vim%20atrav%C3%A9s%20do%20site%20e%20tenho%20interesse%20em%20registrar%C2%A0minha%C2%A0marca%21";
        }, 4000); // 3000 ms = 3 segundos
      })
      .catch((err) => {
        console.error('Erro ao enviar o email:', err);
        alert('Falha ao enviar o email. Tente novamente.');
      });



  }

  return (
    <div id="features" className="text-center">
      <div className="container">
        <div className="col-md-10 col-md-offset-1 section-title">
          <br />
          <h2>Registro de Marca em 3 Passos Simples</h2>
        </div>
        <div className="centered-container">
          <div className="row">
            {props.data
              ? props.data.map((d, i) => (
                <div className="d-flex align-items-center">
                  <div key={`${d.title}-${i}`} className="col-xs-6 col-md-4 text-center">
                    <i className={d.icon}></i>
                    <h3>{d.title}</h3>
                    <p>{d.text}</p>
                  </div>
                </div>

              ))
              : "Loading..."}
          </div>
        </div>


        <br />
        <button onClick={openModal} className="btn btn-custom btn-lg" style={{ marginTop: '15px' }}>
          Pesquisar
        </button>
        <br />
        <br />
        {/* Modal */}
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Consulta de Viabilidade"
        >
          <h2>Consulta de viabilidade da marca</h2>
          <button onClick={closeModal} className="close" style={{ cursor: 'pointer', float: 'right' }}>&times;</button>
          <div>
            {!formVisible ? (
              loading ? (
                <p>Carregando...</p>
              ) : (
                <>
                  <label>Digite o nome da marca que deseja consultar:</label>
                  <input
                    type="text"
                    className="form-control"
                    value={brandName}
                    onChange={(e) => setBrandName(e.target.value)}
                  />
                  <br />
                  <button
                    onClick={handleConclude}
                    className="btn mt-2"
                    style={{ backgroundColor: 'black', color: 'white' }}
                  >
                    Buscar
                  </button>
                </>
              )
            ) : (
              <>
                <p>
                  <strong>Parabéns!</strong><br />
                  Vimos que solicitou a marca "<strong>{brandName}</strong>" para registro.
                </p>
                <p>
                  QUER SABER MAIS? <br />
                  Deixe seus dados abaixo e entraremos em contato com mais informações.
                </p>
                <form>
                  <div className="form-group">
                    <label>Qual o seu nome?</label>
                    <input
                      type="text"
                      className="form-control"
                      value={userName}
                      onChange={(e) => setUserName(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label>Qual o seu Whatsapp?</label>
                    <input
                      type="text"
                      className="form-control"
                      value={userWhatsapp}
                      onChange={(e) => setUserWhatsapp(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label>Qual o seu email?</label>
                    <input
                      type="email"
                      className="form-control"
                      value={userEmail}
                      onChange={(e) => setUserEmail(e.target.value)}
                    />
                  </div>

                  <div className="form-group">
                    <label>Selecione a categoria da marca:</label>
                    <select
                      className="form-control"
                      value={selectedCategory}
                      onChange={(e) => setSelectedCategory(e.target.value)}
                    >
                      <option value="" disabled>Escolha uma categoria</option>
                      <option value="01 - Propaganda; gestão de negócios; funções de escritório.">
                        01 - Propaganda; gestão de negócios; funções de escritório.</option>
                      <option value="02 - Seguros; negócios financeiros; negócios monetários.">
                        02 - Seguros; negócios financeiros; negócios monetários.</option>
                      <option value="03 - Construção civil; reparos; serviços de instalação.">03 - Construção civil; reparos; serviços de instalação.</option>
                      <option value="04 - Telecomunicações.">04 - Telecomunicações.</option>
                      <option value="05 - Transporte; embalagem e armazenagem de produtos.">05 - Transporte; embalagem e armazenagem de produtos.</option>
                      <option value="Tratamento">06 - Tratamento de materiais.</option>
                      <option value="06 - Tratamento de materiais.">07 - Educação; provimento de treinamento; entretenimento.</option>
                      <option value="08 - Serviços científicos e tecnológicos.">08 - Serviços científicos e tecnológicos.</option>
                      <option value="09 - Serviços de fornecimento de comida e bebida.">09 - Serviços de fornecimento de comida e bebida.</option>
                      <option value="10 - Serviços médicos; serviços veterinários; higiene e beleza.">10 - Serviços médicos; serviços veterinários; higiene e beleza.</option>
                      <option value="11 - Serviços jurídicos e sociais.">11 - Serviços jurídicos e sociais.</option>
                    </select>
                  </div>


                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      style={{ marginLeft: '5px' }}
                      checked={acceptedTerms}
                      onChange={() => setAcceptedTerms(!acceptedTerms)}
                      disabled={!userName || !userWhatsapp || !userEmail || !selectedCategory}
                    />
                    <label className="form-check-label">Aceito os termos de uso</label>
                  </div>
                  {(!userName || !userWhatsapp || !userEmail || !selectedCategory) && (
                    <p style={{ color: 'red', marginTop: '10px' }}>
                      Preencha todos os campos para aceitar os termos de uso.
                    </p>
                  )}
                </form>
                <button
                  onClick={handleFinalize}
                  className="btn btn-success mt-3"
                  disabled={!acceptedTerms}
                >
                  Finalizar
                </button>
              </>
            )}
          </div>
        </Modal>
        <ToastContainer />
      </div>
    </div>
  );
};


